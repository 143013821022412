















































import MobileHeader from '@/ui-components/header/mobileHeader/mobileHeader';
export default MobileHeader;
